import React, { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import OauthGoogle from './OauthGoogle';
import List from './List';

function Import() {
    const [file, setFile] = useState(null);
    const [text, setText] = useState('alternance ...');
    const [data, setData] = useState(null);
    const [extension, setExtension] = useState(null);
    let extensionRapide = "";
    const [cookie, setCookie] = useState(null);
    const [encour, setEncour] = useState(null);

    // Nouvelle fonction pour gérer l'appel API
    const callApi = async (formData) => {
        const url = `https://backmotiv.siyan.fr/dataReact?offre=${text}`;
        try {
            const response = await axios.post(url, formData);
            setData(response.data);
            console.log(encour)
        } catch (error) {
            console.log("UNE ERREUR :" + error);
        }
        setEncour(false)
    };

    const handleSubmit = async (e) => {
        setCookie('init');
        e.preventDefault();
        if (file !== null && document.cookie.includes("user=")) {
            setExtension(file.name.split(".").pop());
            extensionRapide = file.name.split(".").pop();

            if (extensionRapide === "pdf") {
                setEncour('true');
                const formData = new FormData();
                formData.append('pdf', file);

                // Appeler la fonction pour gérer l'appel API
                await callApi(formData);

                // Après l'appel API, vous pouvez effectuer d'autres actions si nécessaire

            }
        } else {
            setExtension("NA");
        }
    };

    
    return (
        <>
        <section class="page-section" id="services">
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">Ready To Candidate</h2>
                <h3 class="section-subheading text-muted">Please sign in using your Google account</h3>
            </div>
            <div class="row text-center">
                <div class="col-md-4">
                    <div class="fa-stack fa-4x" >
                        <OauthGoogle /> 
                        </div>
                    <h4 class="my-3">Secure sign-in using Google</h4>
                    <p class="text-muted">A connection is essential for using the tool</p>
                </div>
                <div class="col-md-4">

                    <div  class="fa-stack fa-4x">
                        <div className="import">
                            <form encType='multipart/form-data' onSubmit={handleSubmit}>
                                <input type="file" className="label-file" name="fileToUpload" id="fileToUpload" onChange={(e) => setFile(e.target.files[0])} />
                                <input type="text" name="textToUpload" id="textToUpload" value={text} onChange={(e) => setText(e.target.value)} />
                                
                                {(((extension !== 'pdf') || (extension === "NA")) && (extension !== null)) ? <input style={{ color: 'red' }} type="submit" className='button-13' value="Find" name="submit" /> :<input type="submit" className='button-13' value="Find" name="submit" /> }
                            </form>
                        </div>  
                    </div>
                    <h4 class="my-3">Upload your cover letter and specify the desired job position</h4>
                    <p class="text-muted">Upload your cover letter in PDF format and clearly specify the job position, just as you would on job search websites</p>
                </div>
            </div>
        </div>
        <center>
        {encour ==='true' &&(

        <main>
            <h4>CHARGEMENT EN COUR</h4>
            <svg class="ip" viewBox="0 0 256 128" width="256px" height="128px" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stop-color="#5ebd3e" />
                        <stop offset="33%" stop-color="#ffb900" />
                        <stop offset="67%" stop-color="#f78200" />
                        <stop offset="100%" stop-color="#e23838" />
                    </linearGradient>
                    <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                        <stop offset="0%" stop-color="#e23838" />
                        <stop offset="33%" stop-color="#973999" />
                        <stop offset="67%" stop-color="#009cdf" />
                        <stop offset="100%" stop-color="#5ebd3e" />
                    </linearGradient>
                </defs>
                <g fill="none" stroke-linecap="round" stroke-width="16">
                    <g class="ip__track" stroke="#ddd">
                        <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                        <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                    </g>
                    <g stroke-dasharray="180 656">
                        <path class="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0" d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                        <path class="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358" d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                    </g>
                </g>
            </svg>
        </main>)}
        {(((extension !== 'pdf') || (extension === "NA")) && (extension !== null)) ? <h4 style={{ color: 'red' }}>Le fichier n'est pas un pdf ou il est manquant</h4> : <></>}

        {(document.cookie.includes("user=") || (cookie === null)) ? <></> : <h4 style={{ color: 'red' }}>Connectez-vous pour utiliser l'outil</h4> }
            
        </center>
    </section>
    <List data={data}/>
    </>
        
    )   
}

export default Import;
