import React from 'react'

function About() {
  return (
        <section class="page-section bg-light" id="team">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Whoami</h2>
                    <h3 class="section-subheading text-muted">Student Developper</h3>
                </div>
                <div class="row" id="centerMe">
                    
                    <div class="col-lg-4test">
                    <center>
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="me2.jpg" alt="..."/>
                            <h4>Abdelrahman HAMIDI</h4>
                            <p class="text-muted">FullStack Developper</p>
                            <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/abdelrahman-hamidi-67b944214/" aria-label="Abdelrahman HAMIDI LinkedIn Profile"><svg class="svg-inline--fa fa-linkedin-in" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg></a>
                        </div>
                    </center>
                    </div>



                    <div class="col-lg-4test" id='vertical'>
                        <div >
                            <p>I am Abdelrahman Hamidi, an aspiring DevOps engineer with a fervent interest in web development. Through my experience at Groupe CARSO in Lyon, I delved into data extraction. Moreover, I've conceptualized and developed a platform named Motiv4Job using ReactJS, which automates the customization of cover letters for specific job listings. My foundation in technologies, including ReactJS and Python, coupled with my continuous curiosity about AI and cybersecurity, drives my ambition to innovate in the application development landscape.</p>
                         </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 mx-auto text-center"><p class="large text-muted">I am embarking on a new journey in freelancing, aiming to harness my expertise and passion for software development to provide services to my clients.</p></div>
                </div>
            </div>
        </section>
  )
}

export default About