import React from 'react'

function Persentation() {
  return (

        <header class="masthead">
            <div class="container">
                <div class="masthead-subheading"><strong>Spending too much time customizing your cover letter?</strong></div>
                <div class="masthead-heading text-uppercase">Not with us !</div>
                <a class="btn btn-primary btn-xl text-uppercase" href="#services">Lets'go</a>
            </div>
        </header>

  )
}

export default Persentation