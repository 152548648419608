import './App.css';
import './bootstrap.css'
import Nav from './components/Nav';
import Contact from './components/Contact';
import Persentation from './components/Persentation';
import About from './components/About';
import Import from './components/Import';


function App() {

  return (
    <div className="App">
      <Nav />
      <Persentation />
      <Import />
      <About />
      <Contact />

      
    </div>
//json.parse() pour lire les json
//recup su json avec service (python flask)
//recup cookie avec le sso (auth google...)
  );
}

export default App;
