import React from 'react'

function List(props) {
    const data = props.data;
  return (
    
    <div>
        {data && data.length > 0 && (
        
        <section class="page-section bg-light" id="portfolio">
            <div class="text-center">
                    <h1>Vos lettres de motivations sont prêtes</h1>
                </div>
            <div class="container">
        
                
                <div class="row">


                {data.map((item, index) => (
                <div class="col-lg-4 col-sm-6 mb-4" key={item.key}>
                    <div class="portfolio-item">
                            <a class="portfolio-link" data-bs-toggle="modal" href={item.lien}>
                                <div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><svg class="svg-inline--fa fa-plus fa-3x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M240 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H176V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H240V80z"></path></svg></div>
                                </div>
                                {item.motiv && <p>{item.motiv}</p>}
                            </a>
                            <div class="portfolio-caption">
                                
                                <div class="portfolio-caption-subheading text-muted">Illustration</div>
                            </div>
                        </div>
                    </div>))}
                
                
                
                
                
                
                </div>
            </div>
        </section>
        )}
    </div>
  )
}

export default List