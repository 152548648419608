import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'; 
import { jwtDecode } from "jwt-decode";

function OauthGoogle() {
  return (
    <GoogleOAuthProvider clientId="857176846971-aokdvb4jgvc4gc3igamukpfc05pri9ck.apps.googleusercontent.com">
 

        <GoogleLogin 
          onSuccess={credentialResponse => {
            const decoded = jwtDecode(credentialResponse.credential);
            document.cookie = `user=${decoded["name"]}`;
            document.cookie = `email=${decoded["email"]}`;
          }}
          onError={() => {
            console.log('Échec de la connexion');
          }}
        />

    </GoogleOAuthProvider>
  );
}

export default OauthGoogle;
